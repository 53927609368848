import { Fragment, useEffect, useState } from 'react'

import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Transition } from '@headlessui/react'

import CategoryTextNodes from '@data/i18n/category.json'
import menuData from '@data/menu.json'

import getGtmEcommerceProduct from '@lib/analytics/ecommerce'
import { gtmEvent } from '@lib/analytics/gtm'
import { collectionsUrl, lang, prodUrl } from '@lib/constants'
import useUpdateShopifyFavorites from '@lib/hooks/useUpdateShopifyFavorites'
import type { ProductCard as ProductCardProps } from '@lib/types'
import { getProductBreadcrumbs } from '@lib/utils/breadcrumbs'
import { isCustomizableBoxProduct } from '@lib/utils/isSubscriptionBox'

import StarRatings from '@components/StarRatings'
import Price from '@components/product/ProductView/Price'

export default function ProductCard({
	id,
	handle,
	image,
	name,
	hasStock = true,
	avgRating,
	numReviews,
	price,
	compareAtPrice,
	vendor,
	isCollection,
	isFavorite = undefined,
	hideFavoriteButton = false,
	breadcrumbs,
	collections = [],
	withoutShadow = false,
	isMemberMarket = false
}: ProductCardProps) {
	const [favoriteProduct, setFavoriteProduct] = useState(isFavorite)
	const updateShopifyFavorites = useUpdateShopifyFavorites()
	const { data: session } = useSession()
	const router = useRouter()

	const handleFavorite = () => {
		if (session) {
			const currentFavorite = favoriteProduct
			setFavoriteProduct(!favoriteProduct)
			updateShopifyFavorites(id, !currentFavorite ? 'add' : 'remove')
		}
		// Ask customer to login if they are not logged in
		else router.push(lang === 'es-es' ? '/iniciar-sesion' : '/login')
	}

	const handleAnalytics = () => {
		let productBreadcrumb = breadcrumbs
		if (!productBreadcrumb || !productBreadcrumb.length) {
			productBreadcrumb = getProductBreadcrumbs(collections, menuData.items)
		}
		// Analytics ***
		const gtmEcommerceProduct = getGtmEcommerceProduct({
			product: {
				id,
				name,
				vendor
			},
			price: price?.amount,
			isSubscriptionBox: !!isCustomizableBoxProduct(id),
			productBreadcrumb
		})

		gtmEvent({
			event: 'analytics_event',
			data: {
				eventData: {
					category: 'ecommerce',
					action: 'productClick',
					label: name
				},
				ecommerce: {
					click: {
						actionField: { list: breadcrumbs?.[0]?.title || undefined },
						products: [gtmEcommerceProduct]
					}
				}
			}
		})
	}

	// Analytics ***

	useEffect(() => {
		setFavoriteProduct(isFavorite)
	}, [isFavorite])

	return (
		<div className={`relative ${withoutShadow ? '' : 'shadow-bbx-xl'}`}>
			<Link
				href={{
					pathname: `${
						// eslint-disable-next-line no-nested-ternary
						isCollection ? collectionsUrl : isMemberMarket ? '/members-market' : prodUrl
					}/${handle}`,
					query: router.query
				}}
				key={id}
			>
				<a
					className="product-card"
					onClick={handleAnalytics}
					role="link"
					tabIndex={0}
					onKeyDown={handleAnalytics}
				>
					{/* Compare at price percent discount */}
					{compareAtPrice && Number(compareAtPrice.amount) > Number(price?.amount) && (
						<span className="text-bbx-red font-bold text-xs rounded-sm ml-2 absolute right-3 top-3 z-10">
							SAVE {Math.round(
							((Number(compareAtPrice.amount) - Number(price?.amount)) / 
							Number(compareAtPrice.amount)) * 100
							)}%
						</span>
					)}

					{/* Image */}
					{image?.url ? (
						<div className="product-card-image">
							<Image
								layout="fill"
								objectFit="contain"
								src={image.url}
								alt={image.altText ?? name}
							/>
						</div>
					) : (
						<div
							className="flex items-center justify-center flex-1 h-full mb-6 text-xs text-center min-h-28"
							title={`This ${isCollection ? 'collection' : 'product'} has no image.`}
						>
							<i className="text-5xl text-gray-300 fa-thin fa-image-slash" />
						</div>
					)}

					{/* Brand */}
					{/* TODO: Make this work */}
					{vendor && <span className="px-3 mb-1 text-xs font-medium">{vendor}</span>}

					{/* Product Name */}
					<h3 className="flex-1 px-3">{name}</h3>

					{/* Product Price */}
					{/* {price && (
						<span className="mt-4 text-[17px] font-medium px-3">
							<Price price={price} />
							<Price price={compareAtPrice} />
						</span>
					)} */}

					{/* price */}
					<div className="flex items-center mt-4">
						<div className="text-[17px] font-medium px-3">
							{price && price?.amount !== '0' && (
								<div className={`${compareAtPrice && Number(compareAtPrice.amount) > Number(price.amount) && 'text-bbx-red'}`}>
									<Price price={price} />
								</div>
							)}
						</div>
						{compareAtPrice && Number(compareAtPrice.amount) > Number(price?.amount) && (
							<div className="line-through text-gray-600 text-sm">
								Was <Price price={compareAtPrice} />
							</div>
						)}
					</div>

					{/* {!hasStock && <span className="px-3 mb-1 text-xs font-medium">Out of stock</span>} */}
					{!hasStock && (
						<div
							className="button disabled mx-3 py-1 my-2 max-w-[7rem]"
							title="This product is currently out of stock."
						>
							{CategoryTextNodes.products.outOfStock}
						</div>
					)}

					{/* Ratings */}
					<div className="product-card-reviews flex-wrap">
						{avgRating && numReviews ? (
							<>
								<StarRatings avgRating={avgRating || null} />
								<span className="mt-auto text-xs font-medium">{numReviews.toLocaleString()}</span>
							</>
						) : (
							<span className={`mt-auto text-xs text-gray-500 ${isCollection ? 'hidden' : ''}`}>
								{CategoryTextNodes.products.notReviewed}
							</span>
						)}
					</div>
				</a>
			</Link>

			{/* Favorite product */}
			{isFavorite !== null && !hideFavoriteButton && !isMemberMarket && (
				<Transition
					appear
					as={Fragment}
					show
					enter="transition-opacity duration-250 ease-in-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
				>
					<button
						className={`absolute z-20 right-6 top-4 text-xl ${hideFavoriteButton ? 'hidden' : ''}`}
						type="button"
						onClick={() => handleFavorite()}
					>
						{favoriteProduct !== null && favoriteProduct ? (
							// Favorite
							<i className="fa-heart fa-solid text-bbx-spark" />
						) : (
							// Not a favorite
							<i className="fa-heart fa-light text-gray-350" />
						)}
					</button>
				</Transition>
			)}
		</div>
	)
}
