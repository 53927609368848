import { RichText as RichTextRenderer } from 'prismic-reactjs'
import type { RichTextBlock } from 'prismic-reactjs'

import linkResolver from '@lib/prismic/link-resolver'
import htmlSerializer from '@lib/prismic/serializer'

function RichTextSerializer({ content }: { content: RichTextBlock[] | undefined }) {
	return (
		<RichTextRenderer
			render={content}
			linkResolver={linkResolver}
			htmlSerializer={htmlSerializer}
		/>
	)
}

export default RichTextSerializer
