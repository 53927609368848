import NavTextNodes from '@data/i18n/nav.json'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
const linkResolver = (doc: {
	link_type: string
	type: string
	tags: string[]
	uid: string
	url?: string
}) => {
	if (doc?.url) {
		return doc.url
	}

	if (doc?.uid) {
		if (doc?.type === 'article') {
			return `${NavTextNodes.theMagUrl}/article/${doc.uid}`
		}

		return `/${doc.uid}`
	}

	return '/'
}

export default linkResolver
